<template>
<section class="bggr p-10">

  <h2 class="text-center">ПОЧЕМУ <span class="tp">МЫ</span>?</h2>

    <div class="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
    
      <div class="grid gap-5 md:grid-cols-2 lg:grid-cols-3">
      
        <why-we-element headtext="С НАМИ УДОБНО" counter="1">Задаем вопросы, собираем гайды и пожелания в формате коротких интервью. Никаких длинных брифов.</why-we-element>
        <why-we-element headtext="МЫ – ЭКСПЕРТЫ" counter="2">В нашей команде специалисты с опытом работы более 7 лет в digital. Ответственность за результат – наш главный принцип.</why-we-element>
        <why-we-element headtext="ВСЕГДА НА СВЯЗИ" counter="3">Оперативно отвечаем на вопросы, рассказываем о ходе работ по ключевым этапам, собираем отчеты и аналитику.</why-we-element>
        <why-we-element headtext="РАБОТАЕМ КОМАНДОЙ" counter="4">Мы понимаем, что у вас много задач, поэтому собираем команду под ваш проект так, чтобы вы минимально подключались.</why-we-element>
        <why-we-element headtext="ТОЛЬКО РЕЗУЛЬТАТ" counter="5">Любой продукт должен развиваться и масштабироваться. Прогнозируем риски, смотрим в будущее, анализируем и строим гипотезы.</why-we-element>
        <why-we-element headtext="ЦЕНИМ ВРЕМЯ" counter="6">Выстраиваем прозрачную систему работы. Вы в любой момент можете посмотреть к каким результатам мы пришли.</why-we-element>
     
      </div>
    </div>
</section>

</template>

<script>
import WhyWeElement from '@/components/WhyWeElement.vue'

export default {
    name: 'WhyWe',
    components:{
      WhyWeElement
    }
}
</script>

<style>

</style>