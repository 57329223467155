<template>

<section>
    <div class="px-4 mx-auto max-w-7xl md:px-24 lg:px-8">
       
         <div class="max-w-sm lg:max-w-2xl lg:w-full mx-auto">
              <img
                class="object-cover object-center w-full"
                alt=""
                src="@/assets/images/hello.png"
              />
        </div>


        <h2 class="text-center">КОММУНИКАЦИОННОЕ АГЕНТСТВО <span class="tp">OMYLAB</span></h2>

          <p class="mb-8 text-xl text-center">
            Разрабатываем стратегии и креатив, генерируем контент, ведем социальные сети, настраиваем и запускаем рекламные кампании, работаем с блогерами и осуществляем менеджмент репутации.
          </p>


    </div>
  </section>

        
</template>

<script>

export default {
    name: 'AboutText',
    components: {
    }
}
</script>

<style>

p{
    @apply text-white py-1;
}

</style>