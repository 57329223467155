<template>
  <InnerHead headtext="НАШИ УСЛУГИ">Ценим ваше время. Делаем больше, чем в брифе. Работаем на результат.</InnerHead>
  <ServicesBig></ServicesBig>
  <WhyWe></WhyWe>

</template>
  <!-- <BlogShort></BlogShort>
  <CasesShort></CasesShort> -->
  
<script>
import InnerHead from '@/components/InnerHead.vue'
import ServicesBig from '@/components/ServicesBig.vue'
import WhyWe from '@/components/WhyWe.vue'
// import BlogShort from '@/components/BlogShort.vue'
// import CasesShort from '@/components/CasesShort.vue'

export default {
    name: 'Services',
    components: {
        InnerHead,
        ServicesBig,
        WhyWe,
        // BlogShort,
        // CasesShort
    }
}
</script>

<style>

</style>