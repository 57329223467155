<template>
    <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content} | SITE_NAME` : `SITE_NAME` }}</template>
    </metainfo>
    <Menu></Menu>
    <router-view />
    <ContactForm></ContactForm>
    <Footer></Footer>
</template>

<script>
import Menu from '@/components/Menu.vue'
import ContactForm from '@/components/ContactForm.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'App',
    components: {
        Menu,
        ContactForm,
        Footer
    }
}
</script>

<style>
:root{
    @apply font-omy;
}

body{
  @apply bg-black text-white;
}



</style>
