<template>
  <section>
    <div class="px-4 mx-auto max-w-7xl my-20 md:px-24 lg:px-8">
      <div class="flex flex-wrap items-center mx-auto max-w-7xl">
        <div class="max-w-sm lg:max-w-lg lg:w-1/3 mx-auto">
              <img
                class="object-cover object-center w-full"
                alt=""
                src="@/assets/images/whoweare.png"
              />
        </div>

        <div
          class="
            flex flex-col
            items-start
            text-center
            lg:text-left
            lg:flex-grow lg:w-2/3 lg:pl-6"
        >
          <p class="mb-8 text-xl">
            Разрабатываем стратегии и креатив, ведем социальные сети. Реализуем
            масштабные кросс-функциональные проекты и бросаем вызов самым
            амбициозным бизнес-задачам.
          </p>

          <RButton class="mx-auto lg:m-0" url="/about/">Подробнее</RButton>          
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RButton from "@/components/RButton.vue";

export default {
  name: "RightHead",
  components: {
    RButton,
  },
};
</script>

<style>
</style>