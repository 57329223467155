<template>
  <Hero></Hero>
  <RightHead></RightHead>
  <ServicesShort></ServicesShort>

</template>
  <!-- <BlogShort></BlogShort>
  <CasesShort></CasesShort> -->
  
<script>
import Hero from '@/components/Hero.vue'
import RightHead from '@/components/RightHead.vue'
// import BlogShort from '@/components/BlogShort.vue'
// import CasesShort from '@/components/CasesShort.vue'
import ServicesShort from '@/components/ServicesShort.vue'

export default {
    name: 'Home',
    components: {
        Hero,
        RightHead,
        ServicesShort,
        // BlogShort,
        // CasesShort
    }
}
</script>

<style>

</style>