<template>
  <div class="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 border-indigo-400  border-t-4 mt-10">
    <div class="flex ">
      <div class="w-2/4">
        <a href="/" title="OMYLAB.ru">
        
          <img src="@/assets/logo/logo.svg">


        </a>
        <div class="mt-4 max-w-xs">
          <p class="text-sm">
            Разрабатываем стратегии и креатив, ведем социальные сети.
          </p>


          <div class="flex items-center mt-10 mb-10 space-x-4">


              <a href="https://vk.com/club209067286" target="_blank"  class="text-gray-500 transition-colors duration-300 hover:text-white">
                <svg viewBox="0 0 48 48" fill="currentColor" class="h-5">
                  <path d="M0 23.04C0 12.1788 0 6.74826 3.37413 3.37413C6.74826 0 12.1788 0 23.04 0H24.96C35.8212 0 41.2517 0 44.6259 3.37413C48 6.74826 48 12.1788 48 23.04V24.96C48 35.8212 48 41.2517 44.6259 44.6259C41.2517 48 35.8212 48 24.96 48H23.04C12.1788 48 6.74826 48 3.37413 44.6259C0 41.2517 0 35.8212 0 24.96V23.04Z" />
                  <path d="M25.54 34.5801C14.6 34.5801 8.3601 27.0801 8.1001 14.6001H13.5801C13.7601 23.7601 17.8 27.6401 21 28.4401V14.6001H26.1602V22.5001C29.3202 22.1601 32.6398 18.5601 33.7598 14.6001H38.9199C38.0599 19.4801 34.4599 23.0801 31.8999 24.5601C34.4599 25.7601 38.5601 28.9001 40.1201 34.5801H34.4399C33.2199 30.7801 30.1802 27.8401 26.1602 27.4401V34.5801H25.54Z" fill="black"/>
                </svg>
              </a>

              <a href="https://t.me/omylabru_agency" target="_blank"  class="text-gray-500 transition-colors duration-300 hover:text-white">
                <svg viewBox="0 0 30 30" fill="currentColor" class="h-5">
                  <path d="M29.95,4.74l-5.1,21.02c-0.24,0.99-1.35,1.5-2.26,1.03l-6.45-3.33l-3.05,4.99c-0.83,1.36-2.93,0.77-2.93-0.82v-5.56   c0-0.43,0.18-0.84,0.49-1.14l12.57-12c-0.01-0.15-0.17-0.28-0.33-0.17L7.89,19.2l-5.04-2.6c-1.18-0.61-1.13-2.32,0.09-2.85   L27.79,2.92C28.98,2.4,30.26,3.47,29.95,4.74z"></path>
                </svg>
              </a>



            </div>

        </div>



      </div>


      <div class="w-2/4">
        
        <div class="flex w-full justify-end">
          <ul class="space-y-2 w-1/2 lg:w-1/3">
            <li>
              <a href="/about/" class="font-bold transition-colors duration-300 hover:text-omy-rose">О нас</a>
            </li>
            <li>
              <a href="/contacts/" class="font-bold transition-colors duration-300 hover:text-omy-rose">Контакты</a>
            </li>
            
          </ul>
          <ul class="space-y-2  w-1/2 lg:w-1/3">
            <li>
              <a href="/services/" class="font-bold transition-colors duration-300 hover:text-omy-rose">Услуги</a>
            </li>
            <li>
              <a href="/services/digital/" class="transition-colors duration-300 hover:text-omy-rose text-sm">Цифра</a>
            </li>
            <li>
              <a href="/services/creative/" class="transition-colors duration-300 hover:text-omy-rose text-sm">Креатив</a>
            </li>
            <li>
              <a href="/services/production/" class="transition-colors duration-300 hover:text-omy-rose text-sm">Продакшн</a>
            </li>
             <li>
              <a href="/services/analytics/" class="transition-colors duration-300 hover:text-omy-rose text-sm">Аналитика</a>
            </li>
          </ul>
          <!-- <ul class="space-y-2  w-1/3">
            <li>
              <a href="/blog/" class="font-bold transition-colors duration-300 hover:text-omy-rose">Блог</a>
            </li>
            <li>
              <a href="/cases/" class="font-bold transition-colors duration-300 hover:text-omy-rose">Кейсы</a>
            </li>
            
          </ul> -->
        </div>
        
      </div>

    </div>

    <div class="flex flex-col justify-between pt-5 pb-10 border-t border-gray-800 sm:flex-row">
      <p class="text-sm text-gray-600">
        © 2021 «Коммуникационное агентство OMYLAB»
      </p>
    </div>
    
  </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style>

</style>