<template>
  <InnerHead headtext="КОНТАКТЫ">Давайте создавать сильные бренды вместе.</InnerHead>
</template>

<script>
import InnerHead from '@/components/InnerHead.vue'

export default {
    name: 'Contacts',
    components: {
        InnerHead
    }
}
</script>

<style>

</style>