<template>
    <a :href="url" class="inline-block bg-omy-rose py-3 px-8 rounded-lg text-sm 
    transition duration-200 transform
    hover:-translate-y-0.5 hover:shadow-omy"><slot></slot></a>  
    
</template>

<script>
export default {
    name: 'RButton',
    props:['url']
}
</script>

<style>

</style>