<template>
  <InnerHead headtext="О НАС">Знаем все о продвижении в социальных сетях</InnerHead>
  <AboutText></AboutText>
  <WhyWe></WhyWe>

</template>
  <!-- <BlogShort></BlogShort>
  <CasesShort></CasesShort> -->
  
<script>
import InnerHead from '@/components/InnerHead.vue'
import AboutText from '@/components/AboutText.vue'
import WhyWe from '@/components/WhyWe.vue'
// import CasesShort from '@/components/CasesShort.vue'
// import BlogShort from '@/components/BlogShort.vue'


import { useMeta } from 'vue-meta'

export default {
    name: 'About',
    components: {
        InnerHead,
        AboutText,
        WhyWe,
        // BlogShort,
        // CasesShort,
        
    },
     setup () {
      useMeta({
        title: 'test',
        htmlAttrs: { lang: 'en', amp: true },
        description:  "My page meta description" 
      
      })
  }
}
</script>

<style>

</style>