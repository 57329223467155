<template>

<div class="p-5 bg-black duration-300 transform  hover:-translate-y-2 flex justify-center">
        <div class="text-center">
            <img :src="itemImage" :alt="headtext" class="mx-auto object-cover object-center w-2/3 h-36">
            <h6 class="mb-2 font-semibold leading-5 mt-4">{{headtext}}</h6>
            <p class="text-sm">
                <slot></slot>
            </p>
        </div>
    
</div>
        
</template>

<script>
export default {
    name: 'ServiceItemsElements',
    props:['headtext','image'],
    computed: {
        itemImage() {
        if (!this.image) {
            return
        }
        const fileName = this.image;
        return require('../assets/images/services/'+fileName);
        }
  }
}
</script>

<style>

</style>