<template>
  
<div class=" p-4 mx-auto px-8 lg:mt-10">

<h2 class="mb-10 text-center">КЛЮЧЕВЫЕ НАПРАВЛЕНИЯ <span class="tp">OMYLAB</span></h2>

                <div class="space-y-4 mt-10">
                    <div class="flex items-end justify-center flex-wrap sm:flex-nowrap space-x-10 lg:space-x-24">
                        <a href="/services/digital/" class="w-full sm:w-2/5"><img class="w-full" width="200" src="@/assets/images/services/1.png" alt="Цифра"/></a>
                        <a href="/services/creative/" class="w-full sm:w-2/5"><img class="w-full" width="260" src="@/assets/images/services/2.png" alt="Креатив"/></a>
                    </div>
                    <div class="flex items-end justify-center flex-wrap sm:flex-nowrap space-x-10 lg:space-x-24">
                        <a href="/services/analytics/" class="w-full sm:w-2/5"><img class="w-full" width="170" src="@/assets/images/services/4.png" alt="Аналитика"/></a>
                        <a href="/services/production/" class="w-full sm:w-2/5"><img class="w-full" width="200" src="@/assets/images/services/3.png" alt="Продакшн"/></a>
                    </div>

                </div>
                
</div>


</template>

<script>
// import RButton from '@/components/RButton.vue'

export default {
    name: 'ServicesShort',
    components: {
        // RButton
    }
}
</script>

<style>

</style>