<template>
  <div class="">
    <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
       
       <h2 class="mb-10 text-center">
          НАЧАТЬ РАБОТАТЬ <span class="tp">С НАМИ</span>
       </h2>
    <div class="flex flex-col items-center justify-between ">
    
        <div class="w-full max-w-xl">
          <div class="relative">
            <svg viewBox="0 0 52 24" fill="currentColor" class="absolute bottom-0 right-0 z-0 hidden w-32 -mb-8 -mr-20 text-teal-accent-400 lg:w-32 lg:-mr-16 sm:block">
              <defs>
                <pattern id="766323e1-e594-4ffd-a688-e7275079d540" x="0" y="0" width=".135" height=".30">
                  <circle cx="1" cy="1" r=".7"></circle>
                </pattern>
              </defs>
              <rect fill="url(#766323e1-e594-4ffd-a688-e7275079d540)" width="52" height="24"></rect>
            </svg>
            <div class="relative rounded shadow-2xl p-7 sm:p-10">
              <h3 class="mb-4 text-xl text-center sm:mb-6" v-if="!sent">
                Заполните форму, и мы свяжемся с вами
              </h3>
              <h3 class="mb-4 text-xl text-center sm:mb-6" v-if="sent">
                Спасибо! Заявка отправлена :)
              </h3>

              <form @submit.prevent="onSubmit" v-if="!sent">
                <div class="mb-1 sm:mb-2">
                  <label for="name" class="inline-block mb-1 font-medium">Имя</label>
                  <input
                    placeholder=""
                    required=""
                    type="text"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 text-black bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                    id="name"
                    name="name"
                    v-model="form.name"
                  />
                </div>
                <div class="mb-1 sm:mb-2">
                  <label for="phone" class="inline-block mb-1 font-medium">Телефон</label>
                  <input
                    placeholder=""
                    required=""
                    type="text"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 text-black bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                    id="phone"
                    name="phone"
                    v-model="form.phone"
                  />
                </div>
                <div class="mb-1 sm:mb-2">
                  <label for="email" class="inline-block mb-1 font-medium">E-mail</label>
                  <input
                    placeholder=""
                    required=""
                    type="text"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 text-black bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                    id="email"
                    name="email"
                    v-model="form.email"
                  />
                </div>
                <div class="mb-1 sm:mb-2">
                  <label for="email" class="inline-block mb-1 font-medium">Опишите вашу задачу</label>
                  <textarea
                    placeholder=""
                    required=""
                    type="text"
                    class="flex-grow w-full h-36 px-4 pt-3 mb-2 text-sm transition duration-200 text-black bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                    id="text"
                    name="text"
                    v-model="form.text"
                  />
                </div>
                <div class="mt-4 mb-2 sm:mb-4">
                  <button
                    type="submit"
                    class="inline-flex bg-omy-rose items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                  >
                    Отправить
                  </button>

                </div>
                <p class="text-xs text-gray-600">
                  Нажимая кнопку «Отправить» я даю согласие на обработку своих <a href="">персональных данных</a> и соглашаюсь с <a href="">политикой обработки персональных данных</a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'ContactForm',
    data(){
        return{
            sent:false,
            form: {
                name: '',
                phone: '',
                email: ''
            }
        }
    },
    methods: {
      onSubmit() {
        axios.post('/api/contacts/', this.form)
                 .then((res) => {
                     console.log(res)
                     //Perform Success Action
                 })
                 .catch((error) => {
                     // error.response.status Check status code
                 }).finally(() => {
                    this.sent = true;
                     //Perform action in always
                 });
      }
    }
}
</script>

<style>

</style>