<template>
<!-- hover:text-transparent hover:scale-150  -->
<div class="w-full p-5 px-5 mb-6 sm:mb-2 lg:px-10 duration-200 transform hover:scale-150 text-center"
:class="{ 'scale-125': isActive() }">
    
    <div>
        <a :href="url" class="cursor-pointer font-semibold leading-5 text-5xl sm:text-2xl lg:text-3xl tp hover:text-transparent"
        :class="{ 'add-text-shadow-blue text-transparent': isActive(),  'text-white': !isActive()}"
        ><slot></slot></a>
       
    </div>
</div>
        
</template>

<script>
export default {
    name: 'ServiceButtons',
    props:['url','itemName'],
    methods: {
        isActive: function () {
          return this.itemName === this.$route.params.path
        }
    }
}
</script>

<style>

</style>