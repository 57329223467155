<template>
  <InnerHead headtext="404">Такой страницы здесь нет</InnerHead>
</template>

<script>
import InnerHead from '@/components/InnerHead.vue'

export default {
    name: 'NotFound',
    components: {
        InnerHead,
       
    }
}
</script>

<style>

</style>