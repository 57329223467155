<template>
  <div class="">
    <div class="px-4 pt-10 mx-auto  md:px-24 lg:px-8">
      <div class="sm:mx-auto">
        <div class="flex flex-col mb-16 text-center sm:mb-0">
          <span class="mb-6 mx-auto">
            <div class="flex items-center justify-center w-12 h-12 rounded-full bg-teal-accent-400">
              <svg class="w-64 h-64 text-deep-purple-900" stroke="violet" viewBox="0 0 52 52">
                <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
              </svg>
            </div>
          </span>
          <div class=" mb-10 md:mx-auto text-center md:mb-12">
            <svg viewBox="0 0 52 24" fill="currentColor" class="absolute z-0 hidden w-32 -mt-8 -ml-20 text-deep-purple-accent-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                  <defs>
                    <pattern id="700c93bf-0068-4e32-aafe-ef5b6a647708" x="0" y="0" width=".135" height=".30">
                      <circle cx="1" cy="1" r=".7"></circle>
                    </pattern>
                  </defs>
                  <rect fill="url(#700c93bf-0068-4e32-aafe-ef5b6a647708)" width="52" height="24"></rect>
                </svg>
            <h1 class="mb-6 font-sans text-6xl sm:text-9xl font-bold leading-none tracking-tight text-white md:mx-auto">
              <span class="relative inline-block">
                
                <span class="text-transparent bg-clip-text bg-inner-hero relative" ref="headimage">{{headtext}}</span>
              </span>
              
            </h1>
            <p class="text-base text-indigo-100 text-lg">
              <slot></slot>
            </p>
          </div>          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'InnerHead',
    props:['headtext'],
    data () {
      return {
        x: 500,
        y: 500
      }
    },

    mounted () {
      document.addEventListener('mousemove', this.onMouseMove)
      this.calcData ()
    },

    methods:{
      onMouseMove (ev) {
        this.x = ev.clientX
        this.y = ev.clientY
        this.calcData ()
      },
      calcData () {
        const traX = ((4 * this.x) / 570) + 40;
        const traY = ((4 * this.y) / 570) + 50;
        this.$refs.headimage.style.backgroundPosition = traX + "% " + traY + "%";
      }
    }
}
</script>

<style>

</style>