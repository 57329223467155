import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'
import App from './App.vue'
import '@/styles.css'
import router from '@/router/'

const app = createApp(App);

app.use(router)
app.use(createMetaManager()) 

app.mount('#app')