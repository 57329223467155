import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/layouts/Home.vue'
import Services from '@/layouts/Services.vue'
import Contacts from '@/layouts/Contacts.vue'
import Blog from '@/layouts/Blog.vue'
import BlogView from '@/layouts/BlogView.vue'
import About from '@/layouts/About.vue'
import NotFound from '@/layouts/404.vue'
import Cases from '@/layouts/Cases.vue'

const routes = [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/services/',
        name: 'Services',
        component: Services,
        pathToRegexpOptions: { strict: true } 
      },

      {
        path: '/services/:path/',
        name: 'ServicesInners',
        component: Services,
        pathToRegexpOptions: { strict: true } 
      },

      {
        path: '/contacts/',
        name: 'Contacts',
        component: Contacts,
        pathToRegexpOptions: { strict: true } 
      },
      // {
      //   path: '/cases/',
      //   name: 'Cases',
      //   component: Cases,
      //   pathToRegexpOptions: { strict: true } 
      // },
      // {
      //   path: '/blog',
      //   name: 'Blog',
      //   component: Blog,
   
      // },
      // {
      //         path: '/blog/ir',
      //         name: 'BlogView',
      //         component: BlogView,
      // },
      {
        path: '/about/',
        name: 'About',
        component: About,
        pathToRegexpOptions: { strict: true } 
      },
      {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound,
      },
    ]
    const router = createRouter({
      history: createWebHistory(process.env.BASE_URL),
      routes
    })
    export default router