<template>

<div class="p-5 duration-300 transform bg-black hover:-translate-y-2 flex">
    <div class="flex items-center justify-center w-1/4 text-7xl font-bold text-omy-rose border-r border-omy-rose">
    {{counter}}
    </div>
    <div class="w-3/4 pl-5 justify-center flex items-center">
    <div>
        <h6 class="mb-2 font-semibold leading-5">{{headtext}}</h6>
        <p class="text-sm">
            <slot></slot>
        </p>
    </div>
    </div>
</div>
        
</template>

<script>
export default {
    name: 'WhyWeElement',
    props:['headtext','counter']
}
</script>

<style>

</style>