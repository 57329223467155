<template>
  
          <section class="w-full h-full">
            <div class="
          relative
          items-center
          w-full
          px-5
          py-36
          mx-auto
          md:px-12
          lg:px-16
          max-w-7xl
          lg:py-48
        ">
        <img src="@/assets/images/klaksa.png" class="absolute w-48 lg:w-96 z-0 top-0 right-0">
              <div class="flex w-full mx-auto text-left">
                
                <div class="relative inline-flex items-center mx-auto align-middle">
                   
                  <div class="text-center">
                    <h1 class="
                    relative
                  max-w-5xl
                  text-3xl
                  leading-14
                  font-bold
                  text-neutral-600
                  md:text-5xl
                  lg:text-5xl lg:max-w-7xl
                  text-white
                  z-10
                "> Привет! Мы – OMYLAB.RU <br class="hidden lg:block"> Коммуникационное агентство </h1>
                    
                  </div>
                 
                </div>
              </div>
            </div>
          </section>
        
</template>

<script>
export default {
    name: 'Hero'
}
</script>

<style>

</style>