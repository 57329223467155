<template>
  <div class=" px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
    
        <div class="sm:flex justify-center">
            <service-buttons url="/services/digital/" @click.stop.prevent="pushToPage('digital')" itemName="digital">Цифра</service-buttons>
            <service-buttons url="/services/creative/" @click.stop.prevent="pushToPage('creative')" itemName="creative">Креатив</service-buttons>
            <service-buttons url="/services/production/" @click.stop.prevent="pushToPage('production')" itemName="production">Продакшн</service-buttons>
            <service-buttons url="/services/analytics/" @click.stop.prevent="pushToPage('analytics')" itemName="analytics">Аналитика</service-buttons>
        </div>

    </div>

    <div class="grid gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3">

    <template v-if="$route.params.path === 'digital' ">

      <service-items-elements 
        image="strat.png" 
        headtext="СТРАТЕГИЯ ПРОДВИЖЕНИЯ">
        Разработаем краткосрочную и долгосрочную стратегии продвижения бренда, опишем каналы, TOV, Key message.
      </service-items-elements>

      <service-items-elements 
        image="smm.png" 
        headtext="SMM-ПРОДВИЖЕНИЕ">
        Осуществим ведение социальных сетей под ключ от создания концепции до ведения страниц бренда.
      </service-items-elements>

      <service-items-elements 
        image="targetads.png" 
        headtext="ТАРГЕТИРОВАННАЯ РЕКЛАМА">
        Проанализируем вашу ЦА, рекламу конкурентов, настроим грамотную рекламную кампанию и обеспечим дополнительный приток клиентов.
      </service-items-elements>

      <service-items-elements 
        image="blogers.png" 
        headtext="INFLUENCE-МАРКЕТИНГ">
        Поможем брендам нативно транслировать маркетинговые активности через лидеров мнений и блогеров.
      </service-items-elements>

      <service-items-elements 
        image="content.png" 
        headtext="КОНТЕНТ-МЕНЕДЖМЕНТ">
        Подготовим креативный контент-план, решающий бизнес-задачи и опубликуем его за вас.
      </service-items-elements>

      <service-items-elements 
        image="prsmm.png" 
        headtext="PR В SMM">
        Проведем мониторинг площадок, выберем наиболее подходящие  и организуем масштабную PR-кампанию бренда или продукта.
      </service-items-elements>
      
    </template>


    <template v-if="$route.params.path === 'creative' ">

      <service-items-elements 
        image="concept.png" 
        headtext="КОНЦЕПЦИИ">
        Проанализируем рынок, разработаем работающую рекламную концепцию, которая выгодно отстроит вас от конкурентов.
      </service-items-elements>

      <service-items-elements 
        image="kreoconcept.png" 
        headtext="СЦЕНАРИИ">
        Разработаем и пропишем сценарии роликов, фотосъемок, специальных проектов, маркетинговых активаций и других активностей <nobr>в рамках</nobr> технического задания. 
      </service-items-elements>

      <service-items-elements 
        image="copyright.png" 
        headtext="КОПИРАЙТИНГ/ РЕРАЙТИНГ">
        Напишем текст для любой площадки по вашему запросу, переработаем имеющиеся материалы <nobr>и приведем</nobr> в порядок текстовый контент.
      </service-items-elements>

      
    </template>


<template v-if="$route.params.path === 'production' ">

      <service-items-elements 
        image="photo.png" 
        headtext="ФОТОСЪЕМКА">
        Снимаем в студиях и проводим выездные съемки. Снимаем портреты, репортажи, каталоги, рекламные кампейны.
      </service-items-elements>

      <service-items-elements 
        image="motion.png" 
        headtext="MOTION DESIGN">
        Преобразуем графику в интересный и запоминающийся контент, который привлечет внимание.
      </service-items-elements>

      <service-items-elements 
        image="video.png" 
        headtext="ВИДЕОСЪЕМКА">
        Продумаем сценарий, организуем  съемки любой сложности, сделаем продающие видео для ваших продуктов, снимем рекламные кампейны.
      </service-items-elements>

      <service-items-elements 
        image="text.png" 
        headtext="СЦЕНАРИИ">
        Продумаем и распишем концепт и сценарий съемки, сформируем техническое задание.
      </service-items-elements>

      <service-items-elements 
        image="edit.png" 
        headtext="МОНТАЖ">
        Поможем брендам нативно транслировать маркетинговые активности через лидеров мнений и блогеров.
      </service-items-elements>

      <service-items-elements 
        image="ar.png" 
        headtext="AR-МАСКИ">
        Придумаем креативный концепт и разработаем маску/фильтр для вашего бренда, которая привлечет внимание и повысит охваты.
      </service-items-elements>
      
    </template>


    <template v-if="$route.params.path === 'analytics' ">

      <service-items-elements 
        image="atarget.png" 
        headtext="АУДИТ ТАРГЕТИРОВАННОЙ РЕКЛАМЫ">
        Проведем детальный аудит рекламного кабинета, рекламных кампаний, целевой аудитории и рекламной стратегии, покажем ошибки и дадим рекомендации по улучшению.
      </service-items-elements>

      <service-items-elements 
        image="asmm.png" 
        headtext="АУДИТ SMM">
        Проведем глубокий анализ ваших социальных сетей, покажем ошибки, расскажем как их улучшить, а также увеличить вовлеченность и поднять конверсию.
      </service-items-elements>

      <service-items-elements 
        image="akomplex.png" 
        headtext="КОМПЛЕКСНЫЙ АУДИТ">
        Проведем комплексный аудит социальных сетей и рекламы, расскажем как правильно выстроить работу, чтобы все работало в одной связке.
      </service-items-elements>

      
    </template>


    </div>
  </div>
</template>

<script>

import ServiceButtons from '@/components/ServiceButtons.vue'
import ServiceItemsElements from '@/components/ServiceItemsElements.vue'

export default {
    name: 'ServicesBig',
    components:{
      ServiceButtons,
      ServiceItemsElements
    },
    methods:{
      pushToPage:function(path){
        this.$router.push({ path: '/services/'+path+'/' }) 
      }
    }
}
</script>

<style>

</style>